.fixed-header{
  position: fixed;
  z-index: 100;
}
.web-header, .fixed-header{
  background: #eeeeee;
  padding: 11px 13.59%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  color: #666666;
  font-size:14px;
  font-family:Microsoft YaHei;
  font-weight:400;
  line-height:19px;
  .right-bar{
    display: flex;
    a{
      text-decoration: none;
      color: #666666;
      display: block;
      margin-left: 40px;
    }
    a:link{
      text-decoration: none;
    }
    a:visited{
      text-decoration: none;
    }
    a:hover{
      text-decoration: none;
    }
    a:active{
      text-decoration: none;
    }
  }
}